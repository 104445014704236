<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" style="margin-bottom: 20px;">
					<el-col :span="12" :xs="24"><el-button type="primary" @click="Refresh">刷新</el-button></el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table
					v-loading="serloading"
					:data="tableData"
					:header-cell-style="{ background: '#eef1f6' }"
					border
					stripe
					@expand-change="handleExpandChange"
					:row-key="getRowKeys"
					:expand-row-keys="expands"
				>
					<el-table-column type="expand">
						<template v-slot="">
							<el-table v-loading="serloadings" :data="tableDayData" :header-cell-style="{ background: '#c6f2f6' }" border stripe>
								<el-table-column label="场景" prop="scene" />
								<el-table-column label="视频数">
									<template v-slot="scope">
										<span v-if="durations == 0">{{ scope.row.playedNum }}(0%)</span>
										<span v-else>{{ scope.row.playedNum }}({{ ((scope.row.playedNum / durations) * 100).toFixed(2) }}%)</span>
									</template>
								</el-table-column>
								<el-table-column label="用户数">
									<template v-slot="scope">
										<span v-if="uses == 0">{{ scope.row.userNum }}(0%)</span>
										<span v-else>{{ scope.row.userNum }}({{ ((scope.row.userNum / uses) * 100).toFixed(2) }}%)</span>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
					<el-table-column prop="day" label="日期"></el-table-column>
					<el-table-column prop="playedNum" label="视频数"></el-table-column>
					<el-table-column prop="userNum" label="用户数"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'sceneData',
	data() {
		return {
			emptyText: '暂无数据',
			tableData: [],
			tableDayData: [],
			serloading: false,
			serloadings: false,
			productId: '',
			durations: '',
			uses: '',
			expands: [],
			getRowKeys(row) {
				return row.day;
			}
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 场景数据
		this.Cluserlist();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 场景数据
				this.Cluserlist();
				this.expands = [];
			}
		}
	},
	methods: {
		// 刷新
		Refresh() {
			this.Cluserlist();
		},
		// 场景数据
		Cluserlist() {
			this.serloading = true;
			this.$HTTP.post(
				'sceneData/queryData',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		handleExpandChange(row, expandedRows) {
			var that = this;
			that.durations = row.playedNum;
			that.uses = row.userNum;
			if (expandedRows.length) {
				that.expands = [];
				if (row) {
					that.expands.push(row.day); // 每次push进去的是每行的ID
					this.duration(row);
				}
			} else {
				that.expands = []; // 默认不展开
			}
		},
		duration(row) {
			this.serloadings = true;
			this.$HTTP.post(
				'sceneData/queryDetailData',
				{
					productId: this.productId,
					day: row.day
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableDayData = response.data.data;
							this.serloadings = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
